import { useState, useEffect, useRef } from "react";

function AnimatedText() {
  const wrapperRef = useRef(null);
  const [texts, setTexts] = useState(["", ""]);
  const [positions, setPositions] = useState([{ top: 0, left: 0 }, { top: 0, left: 0 }]);
  const [fontFamilies, setFontFamilies] = useState(["", ""]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const r_text = [
      "KYC",
      "KYB",
      "Data Verification",
      "AML",
      "Emerging Market",
      "Customer Due Diligence",
      "Biometrics",
      "Identity Proofing",
      "Security",
      "Ongoing Monitoring",
      "Background Checks",
      "Identity Verification",
      "Business Verification",
      "Document Verification",
      "Transaction Monitoring",
      "Onboarding Screening",
      "Criminal Record Checks",
      "Education History",
      "Employment History",
      "Fraud Prevention",
      "Fraud Detection",
      "Know Your Customer",
      "Regulatory Compliance",
      "Data Infrastructure",
      "Know Your Business",
      "Compliance Updates",
      "Compliance Advisory",
      "Regulatory Compliance",
      "Digital Security",
      "Digital Infrastructure",
      "Anti-Money Laundering",
      "No-Code Verification",
      "Background Checks",
      "Data",
      "Compliance",
      "Infrastructure",
      "Trust"
    ];

    const fonts = ["Epilogue, sans-serif"];

    const randomizeTextAndPosition = (index) => {
      const wrapper = wrapperRef.current;
      if (!wrapper) return;
      const wrapperHeight = wrapper.offsetHeight;
      const wrapperWidth = wrapper.offsetWidth;

      const margin = 40; 

      const i = Math.floor(Math.random() * r_text.length);
      const newText = r_text[i];

      const randomFontIndex = Math.floor(Math.random() * fonts.length);
      const newFontFamily = fonts[randomFontIndex];
      const minTop = 150;

      let randomTop, randomLeft;

      do {
        randomTop = Math.floor(Math.random() * (wrapperHeight - (-100))) + margin;
        // randomTop = Math.floor(Math.random() * (wrapperHeight - 400)) + margin;
        // randomTop = Math.max(randomTop, minTop); // Ensure top is at least minTop
        randomLeft = Math.max(16, Math.floor(Math.random() * (wrapperWidth - 500)));
      } while (index === 1 && isOverlapping({ top: randomTop, left: randomLeft }, positions[0]));

      setTexts((prev) => {
        const newTexts = [...prev];
        newTexts[index] = newText;
        return newTexts;
      });

      setFontFamilies((prev) => {
        const newFonts = [...prev];
        newFonts[index] = newFontFamily;
        return newFonts;
      });

      setPositions((prev) => {
        const newPositions = [...prev];
        newPositions[index] = { top: randomTop, left: randomLeft };
        return newPositions;
      });
    };

    const isOverlapping = (pos1, pos2) => {
      const distance = 40; 
      return (
        Math.abs(pos1.top - pos2.top) < distance &&
        Math.abs(pos1.left - pos2.left) < distance
      );
    };

    const interval = setInterval(() => {
      const nextIndex = (currentIndex + 1) % 2;
      randomizeTextAndPosition(nextIndex);
      setCurrentIndex(nextIndex);
    }, 5000);

    // Initial randomization
    randomizeTextAndPosition(0);
    randomizeTextAndPosition(1);

    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <div
      ref={wrapperRef}
      className=""
      // style={{
      //   width: "100%",
      //   height: "100%",
      //   overflow: "hidden",
      // }}
    >
      {texts.map((text, index) => (
        <p
          key={index}
          style={{
            top: positions[index].top,
            left: positions[index].left,
            fontFamily: fontFamilies[index],
            fontSize: "55px",
            fontWeight: 600,
            padding: "50px 15px",
            position: "absolute",
            opacity: currentIndex === index ? 1 : 0,
            transition: "opacity 1s ease-in-out",
           
          }}
          className={`randomtextfont text-6xl ${
            currentIndex === index ? "fadeIn" : "fadeOut"
          }`}
        >
          {text}
        </p>
      ))}
    </div>
  );
}

export default AnimatedText;