// import React from "react";
// import master from "../../newassets/image2.svg";
// import Slider from "./Slider";
// // import NavbarComponent from "../components/NavbarComponent";
// // import NavBar2 from "./NavBarv2";
// import NavBar from "./NavBar";
// import AnimatedText from "./TextAnimation";



// const Header = () => {
//   return (
//     <>
//       <div>
//       <section className="section tw-fixed tw-top-0 tw-z-[100]">
       
//   {/* <NavBar isHomePage={true} /> */}
//   <div className="atext">
//     <AnimatedText />
//   </div>
// </section>


//         <main className="container text-center">
//           <section className="about-company">
            
//             <img src={master} className="image2 " alt=""/>  
//             <h2 className="about-comapnay-text"> 
//               A Data Infrastructure
//               <br /> Company
//             </h2>
//             <div className="content-container mx-auto">
//               <p>
//                 Building security, compliance, and verification tools to make
//                 the internet a safer place. Whether it's for security,
//                 compliance reasons or to enter new markets, thousands of
//                 organisations have relied on our solutions.
//               </p>
//               {/* <button className="green-btn">Our Solutions</button> */}
//             </div>
//           </section>
//           <div className="trusted-by ">
//             <h2>Trusted by</h2>
          
//             <Slider />
//           </div>
//         </main>
//         {/* <Footer /> */}
//       </div>
//     </>
//   );
// };

// export default Header;


import React from "react";
import master from "../../newassets/image2.svg";
import Slider from "./Slider";
import NavBar from "./NavBar";
import AnimatedText from "./TextAnimation";
import whatsappIcon from  "../../newassets/whatapp.svg";
import { FloatingWhatapp } from "./FloatingWhatapp";


const Header = () => {
  return (
    <>
      <div>
        <section className="section tw-fixed tw-top-0 tw-z-[100]">
          <NavBar isHomePage={true} />
          <div className="atext">
            <AnimatedText />
          </div>
        </section>

        <main className="container text-center">
          <section className="about-company">
            <img src={master} className="image2" alt="" />
            <h2 className="about-comapnay-text">
              A Data Infrastructure
              <br /> Company
            </h2>
            <div className="content-container mx-auto">
              <p>
                Building security, compliance, and verification tools to make
                the internet a safer place. Whether it's for security,
                compliance reasons or to enter new markets, thousands of
                organisations have relied on our solutions.
              </p>
              {/* <button className="green-btn">Our Solutions</button> */}
            </div>
          </section>
          <div className="trusted-by">
            <h2>Trusted by</h2>
            <Slider />
          </div>
        </main>
        {/* <Footer /> */}

        {/* Floating WhatsApp Icon */}
        {/* <a
          href="https://web.whatsapp.com/send?phone=254796111020&text=Hello%2C%20I%27m%20from%20Peleza%20website%20and%20I%20have%20an%20enquiry"
          className="whatsapp-float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="whatsapp-content">
            <img src={whatsappIcon} alt="WhatsApp" className="whatsapp-icon" />
            <span className="whatsapp-text">How can I help you?</span>
          </div>
        </a> */}
        <FloatingWhatapp/>
      </div>
    </>
  );
};

export default Header;
