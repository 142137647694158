import React, { useState } from "react";
import NavBar2 from "../NavBar";
import Footer from "../Footer";
import { FloatingWhatapp } from "../FloatingWhatapp";
import { Row, Col, Form, Button, Card, ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";

const TrackingPage = () => {
  const [formID, setFormID] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhoneNumber = (phone) => {
    const re = /^\d{10,14}$/; // Matches a phone number with 10 to 14 digits
    return re.test(String(phone));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setStatus(null);
    setError("");
    setSubmitted(true);

    if (!formID.trim()) {
      setError("Email or phone number is required");
      setLoading(false);
      return;
    } else if (!validateEmail(formID) && !validatePhoneNumber(formID)) {
      setError("Enter a valid email address or phone number");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        "https://api.prembly.com/background-check/background/api/v1/bolt_customer_background_check_status",
        {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(
            validateEmail(formID) ? { email: formID } : { phone: formID }
          ),
        }
      );

      if (!response.ok) {
        throw new Error("Something went wrong. Please try again.");
      }

      const data = await response.json();
      if (data.detail && data.detail.length === 0) {
        setStatus(null);
      } else {
        setStatus(data);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const capitalize = (text) => {
    if (!text) return "";
    return text
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const calculateProgressBarValue = () => {
    const completionStatus = parseInt(status?.detail?.completion_status, 10);
    if (isNaN(completionStatus)) {
      return 0;
    }
    if (status?.detail?.paid) {
      return 50 + completionStatus;
    }
    return completionStatus;
  };

  const fullName = `${capitalize(status?.detail?.first_name)} ${
    status?.detail?.middle_name ? capitalize(status?.detail?.middle_name) : ""
  } ${capitalize(status?.detail?.last_name)}`;

  const getRequestStatusStyle = () => {
    switch (status?.detail?.request_status) {
      case "ACCEPTED":
        return {
          backgroundColor: "green",
          color: "white",
          padding: "10px",
          borderRadius: "5px",
        };
      case "PENDING":
        return {
          backgroundColor: "orange",
          color: "white",
          padding: "10px",
          borderRadius: "5px",
        };
      default:
        return {};
    }
  };

  return (
    <>
      <div className="">
        <NavBar2 isHomePage={false} isBoltPage={false} />

        <div className="d-flex align-items-center btnee me-3">
          <FaArrowLeftLong className="me-2" />
          <Link to="/bolt" className="text-decoration-none">
            Go Back
          </Link>
        </div>

        <h1 className="d-flex justify-content-center track tw-font-generalSansRegular w-font-[600] tw-text-[2.5rem]">
          Track Your Bolt Verification
          <br /> Application
        </h1>
        <br />
        <Row className="justify-content-center my-4 tw-font-generalSansRegular">
          <Col xs={12} md={5} className="justify-content-center px-4">
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formID">
                <Form.Label>Tracking Email or Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your email address or phone number"
                  value={formID}
                  onChange={(e) => setFormID(e.target.value)}
                />
                {submitted && error && (
                  <Form.Text className="text-danger">{error}</Form.Text>
                )}
              </Form.Group>

              <Button
                type="submit"
                className="mt-3 w-100 green-btn"
                disabled={loading}
              >
                {loading ? "Submitting..." : "Submit"}
              </Button>
            </Form>
          </Col>
        </Row>

        {/* Display loading message */}
        {loading && (
          <div className="text-center mt-4">
            <h3>Loading...</h3>
          </div>
        )}

        <Row className="justify-content-center tw-font-generalSansRegular w-font-[600]">
          <Col xs={12} md={8} className="px-0">
            {!loading && submitted && status && status.detail ? (
              <div className="mt-4">
                <Card className="mt-4 p-4 shadow">
                  <h5 className="mb-3">Completion Status</h5>
                  <ProgressBar
                    now={calculateProgressBarValue() || "0%"}
                    label={calculateProgressBarValue() + "%" || "0%"}
                    style={{ height: "30px", fontSize: "1.2rem" }}
                    aria-valuenow="0"
                    variant="success"
                  />
                  <Card.Body className="mt-3 px-0">
                    {/* <Row className="mb-3">
                      <Col xs={12} className="d-flex justify-content-between">
                        <strong>Full Name:</strong>
                        <div>{fullName}</div>
                      </Col>
                    </Row> */}
                    {/* <Row className="mb-3">
                      <Col xs={12} className="d-flex justify-content-between">
                        <strong>Nationality:</strong>
                        <div>{capitalize(status.detail.nationality)}</div>
                      </Col>
                    </Row> */}
                    {/* <Row className="mb-3">
                      <Col xs={12} className="d-flex justify-content-between">
                        <strong>Email:</strong>
                        <div>{status.detail.email}</div>
                      </Col>
                    </Row> */}
                    {/* <Row className="mb-3">
                      <Col xs={12} className="d-flex justify-content-between">
                        <strong>Phone Number:</strong>
                        <div>{capitalize(status.detail.phone_number)}</div>
                      </Col>
                    </Row> */}
                    <Row className="mb-3">
                      <Col xs={12} className="d-flex justify-content-between">
                        <strong>Request Status:</strong>
                        <div style={getRequestStatusStyle()}>
                          {capitalize(status.detail.request_status)}
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12} className="d-flex justify-content-between">
                        <strong>Payment Status:</strong>
                        <div>{status.detail.paid ? "Paid" : "Unpaid"}</div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12} className="d-flex justify-content-between">
                        <strong>Payment Amount:</strong>
                        <div>
                          {status.detail.paid
                            ? `KES ${status.detail.amount_paid}`
                            : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12} className="d-flex justify-content-between">
                        <strong>Payment Date:</strong>
                        <div>
                          {status.detail.paid
                            ? new Date(
                                status.detail.date_paid
                              ).toLocaleDateString()
                            : "N/A"}
                        </div>
                      </Col>
                    </Row>
                    {/* <Row className="mb-3">
                      <Col xs={12} className="d-flex justify-content-between">
                        <strong>Background Checks:</strong>
                        <div>
                          {status.detail.checks &&
                          status.detail.checks.length > 0
                            ? status.detail.checks.map((check, index) => (
                                <div style={{ textAlign: "right" }} key={index}>
                                  {capitalize(check)}
                                </div>
                              ))
                            : "N/A"}
                        </div>
                      </Col>
                    </Row> */}
                  </Card.Body>
                </Card>
              </div>
            ) : (
              !loading &&
              submitted &&
              !error &&
              !status?.detail && (
                <div className="text-center mt-4 px-2">
                  <h3 className="tracking-text">
                    <span>
                      Application not found, please visit{" "}
                      <span
                        style={{
                          color: "#2f9d92",
                          fontWeight: "600",
                          cursor: "pointer",
                        }}
                      >
                        <a
                          href="https://prembly.com/bolt"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          www.prembly.com/bolt
                        </a>
                      </span>{" "}
                      to apply for your bolt check
                      <br />
                      For enquiries or support, simply chat with our customer
                      care representative <br />
                      by clicking on the "chat icon" or "Whatsapp icon" below.
                    </span>
                  </h3>
                </div>
              )
            )}
          </Col>
        </Row>
      </div>
      {!loading && submitted && status && status.detail ? (
        <div className="text-center mx-auto py-4 mergerText px-2">
          <h3 className="">
            <span>
              *Note: Your application will take between{" "}
              <span
                style={{
                  color: "#2f9d92",
                }}
              >
                24-48hrs
              </span>{" "}
              to be completed.
              <br /> For enquiries or support, simply chat with our customer
              care representative <br /> by clicking on the "chat icon" or
              "Whatsapp icon" below..
            </span>{" "}
            {/* <a
              href="https://blog.prembly.com/highlights-and-insights-on-the-merger-peleza-international-and-prembly-ltd-as-prembly-group/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read more about our merger
            </a> */}
          </h3>
        </div>
      ) : (
        ""
      )}
      <Footer />
      <FloatingWhatapp />
    </>
  );
};

export default TrackingPage;
