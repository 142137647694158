import React from "react";
// import Footer from "../../components/footer";
import Footer from "../../components/newNavbar/Footer";
import NavbarArea from "../../components/navbar";
import NavBar2 from "../../components/newNavbar/NavBar";
// import team from "../../assets/team.svg";
// import plus from "../../assets/plusimg.svg";

export default function Career() {
  return (
  <>
    <div>
      <NavBar2/>
      <div className="whiteBg"></div>
      <section className="join-us py-5 mb-5">
        <div className="container">
          <div className="col-lg-12 mx-auto">
            <div className="text-center">
              <h1>Join the A-Team.</h1>
            </div>
            <div className="row align-items-center  justify-content-center joins py-4">
              <div className="col-md-10 col-lg-5 join-text">
                <p>
                  The plan is to become the most customer-centric company; build
                  a community of solid teams achieving & fulfilling dreams by
                  creating today's brand and tomorrow's legacy. Do you think you
                  have what it takes to be a part of the team? Then you are
                  doing well, click on the button below to see how to be a part
                  of the awe-mazing team.
                </p>
                <button className="btn seeroles mt-3">
                  <a 
                    
                    href="https://prembly.bamboohr.com/careers"
                    target='_blank'
                    style={{textDecoration: 'none',  color: 'white'   }}
                    >
                    See open roles
                  </a>
                 
                  </button>
              </div>

              <div className="col-md-10 col-lg-5">
                <div className="joinbox">
                  <img src='https://prembly-assets.s3.us-east-2.amazonaws.com/homepage2/team-members.png' alt=""  className="w-100"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="workatprembly">
        <div className="container">
          <div className="col-md-12 col-lg-10 mx-auto mb-5">
            <div className="text-center">
              <h1>Why work at Prembly?</h1>
            </div>
            <div className="benefits py-5">
              <div className="benefits-lisItems">
                <div className="row benefit-lists col-md-12 col-lg-10 mx-auto">
                  <div className="col-sm-6 benefits-items px-5">
                    <div>
                      <img
                        src="https://prembly-assets.s3.us-east-2.amazonaws.com/ne.svg"
                        alt=""
                      />
                    </div>
                    <div>
                      <h5>Competitive Salary</h5>
                      <p>
                        We trust and believe in your skill and the impact it
                        will have on our business and will compensate you as you
                        deserve.
                      </p>
                    </div>
                  </div>

                  <div className="col-sm-6 benefits-items px-5">
                    <div>
                      <img
                        src="https://prembly-assets.s3.us-east-2.amazonaws.com/bags.svg"
                        alt=""
                      />
                    </div>
                    <div>
                      <h5>Remote Work Culture</h5>
                      <p>
                        At Prembly we believe in doing things in an open manner,
                        being honest, consistently honouring commitments and
                        keeping our word.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row benefit-lists col-md-12 col-lg-10 mx-auto py-5">
                  <div className="col-sm-6 benefits-items px-5">
                    <div>
                      <img
                        src="https://prembly-assets.s3.us-east-2.amazonaws.com/great.svg"
                        alt=""
                      />
                    </div>
                    <div>
                      <h5>Continuous Growth</h5>
                      <p>
                        At Prembly, we prioritise growth and as such we create
                        an avenue for our team members to improve their skillset
                        and also further their education.
                      </p>
                    </div>
                  </div>

                  <div className="col-sm-6 benefits-items px-5">
                    <div>
                      <img
                        src="https://prembly-assets.s3.us-east-2.amazonaws.com/vioce.svg"
                        alt=""
                      />
                    </div>
                    <div>
                      <h5>HMO</h5>
                      <p>
                        We take our employees health serious and one of the ways
                        is by giving access to health maintenance organisations.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row benefit-lists col-md-12 col-lg-10 mx-auto ">
                  <div className="col-sm-6 benefits-items px-5">
                    <div>
                      <img
                        src="https://prembly-assets.s3.us-east-2.amazonaws.com/media.svg"
                        alt=""
                      />
                    </div>
                    <div>
                      <h5>Work Tools</h5>
                      <p>
                        We empower and enable all team members to function by
                        providing all needed work tools
                      </p>
                    </div>
                  </div>

                  <div className="col-sm-6 benefits-items px-5">
                    <div>
                      <img
                        src="https://prembly-assets.s3.us-east-2.amazonaws.com/calender.svg"
                        alt=""
                      />
                    </div>
                    <div>
                      <h5>Paid Time Off</h5>
                      <p>
                        We recommend at least 20days time off to rest and do
                        things outside work every year
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div></>
  );
}
