import React, { useState, useEffect } from "react";
import axios from "axios";
import NavbarArea from "../../components/navbar";
// import Footer from "../../components/footer";
import Footer from "../../components/newNavbar/Footer";
import NavBar2 from "../../components/newNavbar/NavBar";
import { identitys } from "../../components/datas";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LatestF from "../allProduct/LatestF";
import { identityT } from "../../components/datas";
import BlueCard from "../allProduct/BlueCard";
import { Link } from "react-router-dom";
import Cards from "../allProduct/Cards";
import Clock from "../Clock";
import gallery6 from "../../assets/events/gallery6.png";
import mention1 from "../../assets/events/mention1.png";
import mention3 from "../../assets/events/mention3.png";
import header from "../../assets/events/header-top.png";
import { Helmet } from "react-helmet";
import photo1 from "../../assets/prembly-assest/photo1.png";
import photo from "../../assets/prembly-assest/photo.png";
import dia from "../../assets/dia1.png";

export default function Events() {
  const [arrowDirection, setArrowDirection] = useState(1);
  const [pressData, setPressData] = useState([]);
  // const [timerDays, setTimerDays] = useState();
  // const [timerHours, setTimerHours] = useState();
  // const [timerMinutes, setTimerMinutes] = useState();
  // const [timerSeconds, setTimerSeconds] = useState();

  const arrowAngleRight = () => {
    setArrowDirection(2);
  };

  const arrowAngleLeft = () => {
    setArrowDirection(1);
  };

  useEffect(() => {
    axios
      .get(
        "https://cms-backend.myidentitypass.com/resource/press/collections/0"
      )
      .then((res) => {
        console.log(res);
        setPressData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // let interval;

  // const startTimer = () => {
  //   const countDownDate = new Date("July, 20,2023").getTime();
  //   interval = setInterval(() => {
  //     const now = new Date().getTime();
  //     const distance = countDownDate - now;
  //     const days = Math.floor(distance / (24 * 60 * 60 * 1000));

  //     const hours = Math.floor(
  //       (distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
  //     );
  //     const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));

  //     const seconds = Math.floor((distance % (60 * 1000)) / 1000);

  //     if (distance < 0) {
  //       clearInterval(interval.current);
  //     } else {
  //       setTimerDays(days);
  //       setTimerHours(hours);
  //       setTimerMinutes(minutes);
  //       setTimerSeconds(seconds);
  //     }
  //     // const days =
  //   });
  // };

  // useEffect(() => {
  //   startTimer();
  // },[]);

  return (
    <div style={{ background: "#ffffff" }}>
      <title>Prembly - Events</title>
      <meta property="og:title" content="Prembly- Event-Dialogue" />
      <meta name="description" content="A Compliance and Security Company" />
      {/* <NavbarArea /> */}
      <NavBar2/>
      <div className="whiteBg"></div>
      <div className="eventHero">
        <div className="container align-items-center ">
          <div className="row home-gap align-items-center justify-content-between">
            <div className="event-title col-md-12 col-lg-6">
              <h1 className="py-3">Prembly Compliance Industry Dialogue III</h1>
              <div className="d-flex align-items-center">
                <a
                  // href="https://us19.list-manage.com/survey?u=1954fa3f95658d99efa44cd11&id=af0e6f9c59&e=*|UNIQID|*"
                  href="https://forms.prembly.com/view/284fbbe6-27ff-4432-bbe2-121f3ce9f83c"
                  className="btn btn-green1 me-1 mt-3"
                  target="_blank"
                  rel="noreferrer"
                  data-luma-action="checkout"
                  data-luma-event-id="evt-NXHeEAShCzgtxms"
                >
                  Join Waitlist
                </a>
                <script
                  id="luma-checkout"
                  src="https://embed.lu.ma/checkout-button.js"
                ></script>
              </div>
            </div>
            {/*             
            <div className="clock-timer">
              <Clock
                timerDays={timerDays}
                timerMinutes={timerMinutes}
                timerHours={timerHours}
                timerSeconds={timerSeconds}
              />
            </div> */}
          </div>
        </div>
      </div>

      <section className="mentions">
        <div className="container py-5 connect">
          <div className="connects">
            <div className="adjust-content">
              <div className="tes">
                <h5>PAST EVENTS</h5>
              </div>
              <div className="newsContainer">
                <>
                  <Link
                    to="/event/dialogueII"
                    style={{
                      textDecoration: "none",
                      color: "#000000",
                    }}
                  >
                    <div className="newsCard">
                      <div className="card mb-5">
                        <div className="selectCompliance">
                          <div className="selectComTitle">
                            <p>COMPLIANCE</p>
                          </div>
                          <img
                            src="https://prembly-assets.s3.us-east-2.amazonaws.com/smalls.png"
                            className="news-header w-100"
                            alt=""
                          />
                        </div>
                        <div className="new-body">
                          <h5 className="pb-3">
                            Prembly Compliance Industry Dialogue II
                          </h5>

                          <hr style={{ width: "60px", marginTop: "-10px" }} />
                          <p>22 July 2023</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </>
                <>
                  <Link
                    to="/event/dialogue"
                    style={{
                      textDecoration: "none",
                      color: "#000000",
                    }}
                  >
                    <div className="newsCard">
                      <div className="card mb-5">
                        <div className="selectCompliance">
                          <div className="selectComTitle">
                            <p>COMPLIANCE</p>
                          </div>
                          <img src={dia} className="news-header w-100" alt="" />
                        </div>
                        <div className="new-body">
                          <h5 className="pb-3">
                            Prembly Compliance Industry Dialogue I
                          </h5>

                          <hr style={{ width: "60px", marginTop: "-10px" }} />
                          <p>22 April 2023</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </>
              </div>
            </div>
            <div className="adjust-content ">
              <div className="tes">
                <h5>MEDIA MENTIONS</h5>
              </div>
              <div className="newsContainer ">
                <div className=" newsCard">
                  <div className="card mb-5">
                    <div className="selectCompliance">
                      <div className="selectComTitle">
                        <p>COMPLIANCE</p>
                      </div>
                      <img
                        src="https://prembly-assets.s3.us-east-2.amazonaws.com/smalls.png"
                        className="news-header w-100"
                        alt=""
                      />
                    </div>

                    <div className="new-body">
                      <a
                        href="https://techbuild.africa/prembly-compliance-tracker-financial-regulations/"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "#000000",
                        }}
                      >
                        <h5 className="pb-3">
                          Prembly Unveils Compliance Tracker to Enhance
                          Compliance in Financial Sector
                        </h5>
                      </a>

                      {/* <p>{values.publication_press.description}</p> */}
                      <hr style={{ width: "60px", marginTop: "-10px" }} />
                      <p>22 July 2023</p>
                    </div>
                  </div>
                </div>

                <div className=" newsCard">
                  <div className="card mb-5">
                    <div className="selectCompliance">
                      <div className="selectComTitle">
                        <p>COMPLIANCE</p>
                      </div>
                      <img src={photo} className="news-header w-100" alt="" />
                    </div>
                    <div className="new-body">
                      <a
                        href="https://technext24.com/2023/07/27/premblys-financial-compliance-parley/"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "#000000",
                        }}
                      >
                        <h5 className="pb-3">
                          Regulations must be flexible to accommodate
                          innovation- Experts at Prembly’s compliance parley
                        </h5>
                      </a>

                      {/* <p>{values.publication_press.description}</p> */}
                      <hr style={{ width: "60px", marginTop: "-10px" }} />
                      <p>22 July 2023</p>
                    </div>
                  </div>
                </div>

                <div className=" newsCard">
                  <div className="card mb-5">
                    <div className="selectCompliance">
                      <div className="selectComTitle">
                        <p>COMPLIANCE</p>
                      </div>
                      <img src={photo1} className="news-header w-100" alt="" />
                    </div>
                    <div className="new-body">
                      <a
                        href="https://techeconomy.ng/prembly-unveils-compliance-tracker-to-enhance-compliance-in-financial-secto"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "#000000",
                        }}
                      >
                        <h5 className="pb-3">
                          Prembly Unveils Compliance Tracker to Enhance
                          Compliance in Financial Sector
                        </h5>
                      </a>

                      {/* <p>{values.publication_press.description}</p> */}
                      <hr style={{ width: "60px", marginTop: "-10px" }} />
                      <p>22 July 2023</p>
                    </div>
                  </div>
                </div>

                <div className=" newsCard">
                  <div className="card mb-5">
                    <div className="selectCompliance">
                      <div className="selectComTitle">
                        <p>COMPLIANCE</p>
                      </div>
                      <img
                        src={mention1}
                        className="news-header w-100"
                        alt=""
                      />
                    </div>
                    <div className="new-body">
                      <a
                        href="https://businessday.ng/news/article/cbn-to-clean-up-tier-1-accounts-not-linked-to-bvn/"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "#000000",
                        }}
                      >
                        <h5 className="pb-3">
                          CBN to clean up Tier-1 accounts not linked to BVN
                        </h5>
                      </a>

                      {/* <p>{values.publication_press.description}</p> */}
                      <hr style={{ width: "60px", marginTop: "-10px" }} />
                      <p>6 April 2023</p>
                    </div>
                  </div>
                </div>

                <div className=" newsCard">
                  <div className="card mb-5">
                    <div className="selectCompliance">
                      <div className="selectComTitle">
                        <p>COMPLIANCE</p>
                      </div>
                      <img
                        src={gallery6}
                        className="news-header w-100"
                        alt=""
                      />
                    </div>
                    <div className="new-body">
                      <a
                        href="https://www.thisdaylive.com/index.php/2023/04/13/insider-driven-fraud-biggest-challenge-facing-nigerias-finance-industry-experts-say"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "#000000",
                        }}
                      >
                        <h5 className="pb-3">
                          Insider-Driven Fraud Biggest Challenge Facing
                          Nigeria’s Finance Industry, Experts Say
                        </h5>
                      </a>

                      {/* <p>{values.publication_press.description}</p> */}
                      <hr style={{ width: "60px", marginTop: "-10px" }} />
                      <p>6 April 2023</p>
                    </div>
                  </div>
                </div>

                <div className=" newsCard">
                  <div className="card mb-5">
                    <div className="selectCompliance">
                      <div className="selectComTitle">
                        <p>COMPLIANCE</p>
                      </div>
                      <img
                        src={mention3}
                        className="news-header w-100"
                        alt=""
                      />
                    </div>
                    <div className="new-body">
                      <a
                        href="https://www.linkedin.com/feed/update/urn:li:activity:7052294370208694272/"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "#000000",
                        }}
                      >
                        <h5 className="pb-3">
                          Issues around compliance are not negotiable in the
                          financial sector - Dr. Blaise Ijebor, Chief Risk
                          Officer, CBN
                        </h5>
                      </a>

                      {/* <p>{values.publication_press.description}</p> */}
                      <hr style={{ width: "60px", marginTop: "-10px" }} />
                      <p>6 April 2023</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="countries-section py-5">
        <div className="container">
          <div className="row align-items-center mx-auto justify-content-center countries-gap">
            <div className="col-md-10 col-lg-6 countries-list ">
              <div className="global-color mb-4">
                <p>Global</p>
              </div>
              <h1>Our solution cut across 100+ countries</h1>
              <h5 className="mt-3 mb-4">
                Prembly's extensive range of over 100 APIs can be utilised by
                businesses worldwide to enhance their identity verification and
                authentication processes. Our dedicated teams, located globally,
                are ready to assist you and your customers wherever your
                operations may be
              </h5>
              <div className="countriesFlag">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/countries.svg"
                  alt=""
                />
              </div>

              <div className="d-flex align-items-center">
                <Link
                  to="/about"
                  className="btn btn-green1  mt-4 me-1"
                  rel="noreferrer"
                >
                  Learn More
                </Link>
              </div>
            </div>
            <div className="col-md-10 col-lg-5">
              <img
                src="https://prembly-assets.s3.us-east-2.amazonaws.com/world-new.gif"
                alt=""
                className="w-100"
              />
            </div>
          </div>
        </div>
      </section>

      <section id="feedback" className="feedback-prembly-area">
        <div className="container">
          <div className="col-md-12 col-lg-11 mx-auto py-lg-5 text-center">
            <h1>Our Customers Perspective</h1>
            {arrowDirection === 1 && (
              <div className=" d-flex align-items-center py-5 ">
                <div className="showflex row ">
                  {identitys.map((data) => (
                    <div className="col-md-6 col-lg-4">
                      <Cards
                        comment={data.comment}
                        name={data.name}
                        small={data.small}
                      />
                    </div>
                  ))}
                </div>
                <img
                  onClick={arrowAngleRight}
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/rewindright.svg"
                  alt=""
                  className="ms-2"
                />
              </div>
            )}

            {arrowDirection === 2 && (
              <div className=" d-flex align-items-center py-5 ">
                <img
                  onClick={arrowAngleLeft}
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/rewindleft.svg"
                  alt=""
                  className="me-2"
                />
                {/* <div className=" showflex"> */}
                <div className="showflex row ">
                  {identityT.map((data) => (
                    <div className="col-md-6 col-lg-4">
                      {/* <Slider {...settings}> */}

                      <Cards
                        comment={data.comment}
                        name={data.name}
                        small={data.small}
                      />

                      {/* </Slider> */}
                    </div>
                  ))}
                </div>
                {/* <img src={rewindright} alt="" className="ms-2"/> */}
              </div>
            )}
          </div>
        </div>
      </section>

      <LatestF />
      <Footer />
    </div>
  );
}
