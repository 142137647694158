import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import bolt from "../../newassets/bolt.svg";
import equity from "../../newassets/eqyuity.svg";
import unilever from "../../newassets/unilever.svg";
import lst from "../../newassets/londonstockexchange_logo 1.svg";
// import master from "../assests/master.svg";
import master from "../../newassets/master.svg";

const Slider = () => {
  return (
    <div className="container my-5">
    
          <div className="image-grid">
            <img
              className="img-fluid"
              src={bolt}
              alt="Bolt"
              
            />
            <img
              className="img-fluid"
              src={unilever}
              alt="Unilever"
            />
            <img
              className="img-fluid"
              src={master}
              alt="Mastercard"
            />
            <img
              className="img-fluid"
              src={equity}
              alt="Equity"
            />
            <img
          
              className="img-fluid lst"
              src={lst}
              alt="UnionBank"
            />
          </div>
     
    </div>
  );
};

export default Slider;
