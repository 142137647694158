import Footer from "./Footer";
 
import Header from "./Headers";
import { Helmet } from "react-helmet";

const NewHomepage = ()=> {
  return (
    <>

{/* Please let's change the title to: "Prembly - A Data Infrastructure Company."
Description: "Building security, compliance, and verification tools to make the internet a safer place for everyone." */}
      


      <Helmet>
        <title>Prembly - A Data Infrastructure Company.</title>
        <meta
          name="description"
          content="Building security, compliance, and verification tools to make the internet a safer place for everyone."
        />
      </Helmet>
      <div>

      <Header />
     
      <Footer />
    </div>
    </>
  );
}

export default NewHomepage;
