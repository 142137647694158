import React, { useState, useEffect } from "react";
import axios from "axios";
import { newsData } from "../../components/contactUsData";
import moment from "moment";



export default function NewsComp() {
  const [pressData, setPressData] = useState([]);

  useEffect(() => {
    axios
      .get(newsData)
      .then((res) => {
        console.log(res);
        setPressData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // console.log(pressData);

  let pressImg = (passedContent)=>{
    const tempElement = document.createElement('div');
    tempElement.innerHTML = passedContent;

    // Find the first img tag inside figure tag
    const firstImgElement = tempElement.querySelector('figure img');
    // Get the src value from the first img element
    const firstSrcValue = firstImgElement?.getAttribute('data-src');

    return firstSrcValue
  }

  return (
    <div className="py-5">
      <div className="lastestNews ">
        {pressData
          ? pressData?.map((values) => (
            values?.publication_press !== null && 
              <div className=" newsCard">
                {values.publication_press !== null && (
                  <div
                    className="card mb-5"
                    key={values.publication_press?.id}
                    body={values.publication_press?.body}
                  >
                    
                    {/* {pressImg(values?.content?.rendered)} */}
                    
                    <img
                      // src={values.publication_press?.image_banner}
                      src= {pressImg(values?.content?.rendered)}
                      className="news-header w-100"
                      alt=""
                     
                    />

                    <div className="new-body">
                      <a
                        // href={values.publication_press?.link}
                        href={values?.link}
                        target="_blank"
                        style={{ textDecoration: "none", color: "#000000" }} 
                        rel="noreferrer"
                      >

                        <h5 className="pb-3" dangerouslySetInnerHTML={{__html: values?.title?.rendered}} />
                          {/* {values?.title?.rendered} */}
                        {/* </h5> */}
                      </a>

                      {/* <p>{values.publication_press.description}</p> */}
                      <hr style={{width:'60px', marginTop:'-10px'}}/>
                      <p>
                        {/* {values.publication_press?.published_dated} */}
                        {moment(values?.modified_gmt).format('LL')}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ))
          : null}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <a href="https://blog.prembly.com/" target='_blank' className="btn btn-greener" rel="noreferrer">Read more articles on Blog</a>
      </div>
    </div>
  );
}
